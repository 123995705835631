<template>
  <div>
    <RightWindow :title="title" @closedWindow="closedWindow">
        <div class="center_b_2">
          <ul class="ultab">
            <li class='hover'><a href="javascript:void(0)">详细信息</a></li>
          </ul>
        </div>
        <div class="center_b_3">
          <ul>
            <li class="up"><span class="import">规则名称：</span><input type="text" v-model="form.name"/></li>
            <li class="up"><span class="import">分配门店：</span>
              <select v-model="form.fenpeimendianId" @change="setStoreName" :disabled="this.$store.state.User.jibieId!=0">
                <option :value="0">全部门店</option>
                <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
              </select>
            </li>
            <li class="up"><span class="import">考勤类型：</span>
              <select v-model="form.clockInRules">
                <option :value="1">定点制</option>
                <option :value="2">工时制</option>
              </select>
            </li>
            <template v-if="form.clockInRules==1">
              <li class="up"><span>上班时间：</span>
                <select v-model="form.AMStartH">
                  <option :value="i-1" v-for="i in 24" :key="i">{{i-1}}</option>
                </select>
                时
                <select v-model="form.AMStartM">
                  <option :value="i-1" v-for="i in 60" :key="i">{{i-1}}</option>
                </select>
                分
              </li>
              <li class="up"><span>下班时间：</span>
                <select v-model="form.PMEndH">
                  <option :value="i-1" v-for="i in 24" :key="i">{{i-1}}</option>
                </select>
                时
                <select v-model="form.PMEndM">
                  <option :value="i-1" v-for="i in 60" :key="i">{{i-1}}</option>
                </select>
                分
              </li>
            </template>
            <li class="up" v-else><span class="import">工时(小时)：</span><input type="number" v-model.number="form.workingHours"/></li>
            <li class="up"><span>位置经度：</span><input type="text" v-model.number="form.longitude"/> <a href="javascript:void(0)" @click="positionShow=true">定位帮助</a></li>
            <li class="up"><span>位置纬度：</span><input type="text" v-model.number="form.latitude"/> <a href="https://tool.lu/coordinate/" target="_blank">定位网站</a></li>
            <li class="up"><span>误差距离：</span><input type="text" v-model.number="form.mistake"/> 米</li>
            <li class="up"><span>备注信息：</span><textarea v-model="form.info"></textarea></li>
          </ul>
          <div class="center_b_4">
            <button class="settab" @click="subData" :disabled="onsub">保存</button>
            <button class="remole" @click="closedWindow">取消</button>
          </div>
        </div>
    </RightWindow>
    <div class="meg_all" v-if="positionShow">
      <div class="meg">
        <h1>电脑版设置位置示意图</h1>
        <div class="meg_g"><div class="meg_scroll" style="text-align: center;"><img src="@/assets/images/potion.jpg" width="917" height="1087"></div></div>
        <div class="meg_c"><button type="button" class="off" @click="closdMeg()">关闭</button></div>
        <div class="meg_cld" @click="closdMeg()"><i class="icon-x"></i></div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from '@/http/axios'
import {deepCopy,testStr} from '@/utils/util'
export default {
  name: 'PSinfo',
  props:{
    MmsData:Object,
    title:String,
  },
  watch:{
    'MmsData':{
      handler(newD){
        if(newD.new){
          this.form=deepCopy(this.defform)
        }else{
          this.form=deepCopy(newD.data)
        }
      },
      deep:true
    }
  },
  data() {
    return {
      positionShow:false,
      onsub:false,
      userErrClass:'',
      userErr:'',
      storeList:[],
      shareList:[],
      form:{
        name:'',
        fenpeimendianId:this.$store.state.User.fenpeimendianId,
        fenpeimendianName:this.$store.state.User.fenpeimendianName,
        clockInRules:1,
        workingHours:9,
        AMStartH:8,
        AMStartM:30,
        PMEndH:18,
        PMEndM:0,
        longitude:null,
        latitude:null,
        mistake:null,
        info:'',
      },
      defform:{
        name:'',
        fenpeimendianId:this.$store.state.User.fenpeimendianId,
        fenpeimendianName:this.$store.state.User.fenpeimendianName,
        clockInRules:1,
        workingHours:9,
        AMStartH:8,
        AMStartM:30,
        PMEndH:18,
        PMEndM:0,
        longitude:null,
        latitude:null,
        mistake:null,
        info:'',
      }
    }
  },
  created() {
    this.init()
  },
  components: {},
  mounted() {},
  methods: {
    closdMeg(){
      this.positionShow=false
    },
    init(){
      this.getStore()
      if(!this.MmsData.new){
        this.form=deepCopy(this.MmsData.data)
      }
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    setStoreName(){
      let store=this.storeList.find(e=>e.id==this.form.fenpeimendianId)
      if(store) this.form.fenpeimendianName=store.storeName
      else this.form.fenpeimendianName="全部门店"
    },
    closedWindow(){
      this.$emit('closedWindow')
    },
    subData(){
      if(testStr(this.form.name)){
        this.$Mesg({content: '规则名称不能为空',errOrSuc: 'war',})
        return
      }
      if(this.form.clockInRules==1){
        if(testStr(this.form.AMStartH)||testStr(this.form.AMStartM)||testStr(this.form.PMEndH)||testStr(this.form.PMEndM)){
          this.$Mesg({content: '考勤时间不完整',errOrSuc: 'war',})
          return
        }
      }else{
        if(testStr(this.form.workingHours)){
          this.$Mesg({content: '请填写工时',errOrSuc: 'war',})
          return
        }
      }
      this.form.longitude=this.form.longitude?parseFloat(this.form.longitude):null
      this.form.latitude=this.form.latitude?parseFloat(this.form.latitude):null
      this.form.mistake=this.form.mistake?parseInt(this.form.mistake):null
      let r=this.MmsData.CQList.find(e=>e.fenpeimendianId==this.form.fenpeimendianId)
			if(r) this.form.id=r.id
      axios.post(this.form.id?'/kaoqin/edit':'/kaoqin/add',this.form).then((res) => {
        if(res.code===5000){
          this.$Mesg({content: this.MmsData.new?'新增考勤规则成功！':'修改考勤规则成功！'})
          this.$parent.getData(1)
          this.closedWindow()
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
  },
}
</script>
