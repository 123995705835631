<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li>
          <p>所属门店：</p>
          <select @change="getData(1)" v-model="form.fenpeimendianId">
            <option value="">全部</option>
            <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
          </select>
        </li>
        <li>
          <button type="button" @click="getData(1)">查询</button>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
    <div class="home_list">
      <div class="list_d">
      <table>
        <thead>
          <tr>
            <td>规则名称</td>
            <td>分配门店</td>
            <td>考勤类型</td>
            <td>考勤规则</td>
            <td>考勤地点</td>
            <td>备注信息</td>
            <td>操作</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in dataList" :key="item.id">
            <td>{{item.name}}</td>
            <td>{{item.fenpeimendianName}}</td>
            <td>{{item.clockInRules==1?"定点制":"工时制"}}</td>
            <td>{{getTime(item)}}</td>
            <td>{{getPostion(item)}}</td>
            <td>{{item.info}}</td>
            <td>
              <div class="buttonGroup">
                <button type="button" v-if="countPow(item.fenpeimendianId)" class="suc" @click="setInfoData(item)" v-pow:2802>管理</button>
                <button type="button" v-if="countPow(item.fenpeimendianId)" class="err" @click="delOneData(item.id,item.name)" v-pow:2803>删除</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
    <div class="list_c">
      <div class="c_buttom">
        <button type="button" class="suc" @click="newProductAdd" v-pow:2801>新增考勤规则</button>
      </div>
      <div class="clear"></div>
    </div>
    <PSinfo :title="title" v-if="infoShow" :MmsData="MmsData" @closedWindow="closedWindow"></PSinfo>
  </div>
</template>

<script>
import PSinfo from './PSinfo.vue'
import axios from '@/http/axios'
export default {
  name: 'attendanceSetting',
  data() {
    return {
      infoShow: false,
      title:'',
      dataList:[],
      storeList:[],
      shareList:[],
      form:{
        fenpeimendianId:'',
      },
      MmsData:{
        new:true,
        CQList:[]
      },
    }
  },
  created() {
     this.getData(1)
     this.getStore()
  },
  computed:{
    countPow(){
      return (fenpeimendianId)=>{
        if(this.$store.state.User.jibieId==0) return true
        if(this.$store.state.User.fenpeimendianId==fenpeimendianId) return true
        else return false
      }
    }
  },
  components: {PSinfo},
  mounted() {},
  methods: {
    getTime(item){
      if(item.clockInRules==1){
        const{AMStartH,AMStartM,PMEndH,PMEndM}=item
        return `${AMStartH<10?'0'+AMStartH:AMStartH}:${AMStartM<10?'0'+AMStartM:AMStartM}-${PMEndH<10?'0'+PMEndH:PMEndH}:${PMEndM<10?'0'+PMEndM:PMEndM}`
      }else{
        return `${item.workingHours}(小时)`
      }
    },
    getPostion(item){
      if(item.longitude&&item.latitude&&item.mistake) return '已设置'
      else return '未设置'
    },
    delOneData(id,name){
      this.$trueOrFalse({
        title: '删除提示',
        content: `确认删除 ${name} ?`,
        torf: false,
        suc:()=>{
          axios.post('/kaoqin/del',{id,name}).then((res) => {
            if(res.code===5000){
              this.$Mesg({content: res.msg})
              this.getData(1)
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },
    setInfoData(item){
      this.title=item.name
      this.MmsData.new=false
      this.MmsData.data=item
      this.infoShow=true
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getData(){
      axios.post('/kaoqin/getKQList',this.form).then((res) => {
        if(res.code===5000){
          this.dataList=res.data
          this.MmsData.CQList=this.dataList.map(e=>{
            return {
              id:e.id,
              fenpeimendianId:e.fenpeimendianId
            }
          })
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    closedWindow(){
      this.infoShow=false
    },
    newProductAdd(){
      this.title="新增考勤规则"
      this.MmsData.new=true
      this.infoShow=true
    },
  },
}
</script>
